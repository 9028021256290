import React, { useRef } from 'react'
import {
  HeaderContainer,
  SearchContainer,
  Title,
  LogoContainer,
  NavigationContainer,
  ResponsiveMenu,
  SearchContainerResponsive,
  BackgroundColor,
} from "./styles";
import MenuRight from "./upcc-MenuRight";
import Logo from "../../images/logo_upc.png";
import SearchInput from "./SearchInput";
import { Link } from 'gatsby';
import { pageCategories } from "../variables/variables";

const Header = () => {
  const Background = useRef(null);
  const InputSearch = useRef(null);
  const CategoriesRef = useRef(null);
  const addActive = () => {
    if (!InputSearch.current.classList.contains('active-search')) {
      InputSearch.current.classList.add("active-search")
      Background.current.classList.add("active-background")
    } else {
      InputSearch.current.classList.remove("active-search")
      Background.current.classList.remove("active-background")
    }
  }

  const HandleCategories = (action) => {
    if (action === 1) CategoriesRef.current.classList.add("categories-active")
    if (action === 2) CategoriesRef.current.classList.remove("categories-active")
  }

  return (
    <>
      <HeaderContainer>
        <LogoContainer>
          <Link to="/"><Title>UPC CULTURAL</Title></Link>
          <div></div>
          <img alt="UPC" src={Logo} />
        </LogoContainer>
        <NavigationContainer className="upcc-header">
          <ul>
            <li><Link className="upcc-nav-txt" to="/noticias">NOTICIAS</Link></li>
            <li><Link className="upcc-nav-txt" to="/galeria">GALERÍA</Link></li>
            <li><Link className="upcc-nav-txt" to="/programas">PROGRAMAS</Link></li>
            <li><Link className="upcc-nav-txt" to="/podcasts">PODCASTS</Link></li>
            <li><Link className="upcc-nav-txt" to="/agenda">AGENDA</Link></li>
          </ul>
          <SearchContainer>
            <SearchInput addActive={addActive} />
          </SearchContainer>
          <ResponsiveMenu>
            <button className="mr-4" onClick={addActive}>
              <i className="fa fa-search" aria-hidden="true" />
            </button>
            <button onClick={() => document.getElementById("menu-right-control").click()}>
              <i className="fa fa-bars" aria-hidden="true" />
            </button>
          </ResponsiveMenu>
        </NavigationContainer>
        <SearchContainerResponsive ref={InputSearch}>
          <SearchInput addActive={addActive} />
        </SearchContainerResponsive>
        <MenuRight addActive={addActive} />
      </HeaderContainer>
      <BackgroundColor><button aria-label="disable search" onClick={addActive} ref={Background} /></BackgroundColor>
    </>
  )
}

export default Header
