import React, { useState } from 'react'
import { navigate } from "@reach/router"
import SearchIcon from '../../images/icon-search.png'

const SearchInput = ({ addActive }) => {
  const [text, setText] = useState("");
  const Redirect = () => {
    if (text) {
      navigate(`/arteycultura?search=${text}`)
    } else {
      navigate(`/noticias`)
    }
    addActive()
  }
  return (
    <>
      <input onChange={({ target: { value } }) => setText(value)} onKeyDown={({ keyCode }) => keyCode === 13 && Redirect()} />
      <button onClick={Redirect}>
        <img src={SearchIcon} alt="search-icon" />
      </button>
    </>
  )
}

export default SearchInput
