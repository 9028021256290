export const pageCategories = [
  { url: 'agendacultural', text: 'Agenda Cultural' },
  { url: 'artesaudiovisuales', text: 'Artes audiovisuales' },
  { url: 'artesescenicas', text: 'Artes escénicas' },
  { url: 'artesplasticas', text: 'Artes plásticas' },
  { url: 'literaturaypublicaciones', text: 'Literatura y publicaciones' },
  { url: 'disenoyartesvisuales', text: 'Diseño y artes visuales' },
  { url: 'enexhibicion', text: 'En exhibición' },
  { url: 'musicaycreacionsonora', text: 'Música y creación sonora' },
  { url: 'patrimonio', text: 'Patrimonio' },
  { url: 'quienesomos', text: 'Quiénes somos' },
  //{ url: 'sellobicentenario', text: 'Sello Bicentenario' },
  { url: 'selloupc', text: 'Sello UPC' },
  { url: 'upccultural', text: 'UPC Cultural ®' }
]