import styled from '@emotion/styled';

export const MenuRightContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  background: #1f1e1e;
  opacity: 100;
  top: 0;
  overflow: auto;
  display: block;
  z-index: 1001;
  transition: all 0.5s ease-in-out;
  right: ${({ showRightMenu }) => showRightMenu ? "0" : "-100%"};
  & #menu-right-control{
    border-radius: 100%;
    background-color: #000;
    cursor: pointer;
    padding: 5px;
    margin-top: 5px;
    width: 35px;
    height: 35px;
    display: inline-block;
    color: #fff;
    font-family: arial;
    font-weight: 900;
    margin-right: 4px;
    font-size: 25px;
    margin-top: -2px;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  & img{
    width: 45px;
  }
  & span{
    display: block;
    margin-top: -5px;
  }
  & li{
    text-align: initial;
    width: 100%;
    display: inline-block;
    text-decoration: none;
    font-size: 30px;
    color: #fff;
    font-family: SolanoGothicMVBStd-Bd;
    padding: 0;
    border-bottom: 1px solid #fff;
    padding: 3.2% 0;
    a {
      color: #FFF;
    }
  }
  & button{
    color: #fff;
  }
  & .categories{
    height: 0px;
    transition: all 1s;
    overflow:hidden;
    padding: .2rem 0;
  }
  & .categories-active{
    height: 588px;
  }
  & .categories li {
    font-size: 22px;
    padding: .2rem 0 .2rem 1rem;
  }
  @media only screen and (max-width: 991px) {
    display: block;
  }
`

export const BackgroundColor = styled.div`
  width: 100%;
  height: 100%;
  margin: 0;
  border: 0;
  position: absolute;
  top: 0;
  z-index: 999;
  pointer-events: none;
  transition: all 0.5s;
  display: none;
  & *{
    transition: all 0.5s;
  }
  & button{
    width: 100%;
    height: 0;
    opacity: 0;
    margin: 0;
    border: 0;
    outline: none !important;
    background: rgba(0,0,0,.9);
  }
  & .active-background{
    pointer-events: all;
    opacity: 1;
    height: 100%
  }
  @media only screen and (max-width: 991px) {
    display: block;
  }
`

export const HeaderContainer = styled.nav`
  height: 61px;
  background: #fff;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  z-index: 1000;
  transition: all .6s ease;
  font-style: normal!important;
  & button{
    background: unset;
    border: 0px;
    outline: none !important;
  }
  & .active-search{
    pointer-events: all;
    opacity: 1;
  }
  @media only screen and (max-width: 625px) {
    // height: 70px;
  }
`
export const ResponsiveMenu = styled.div`
  display: none;
  & i{
    font-size: 24px;
    color: #706e6e;
    transition: all .2s ease;
  }
  @media only screen and (max-width: 991px) {
    display: flex;
  }
`

export const LogoContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  & img{
    width: 50px;
    margin: 0 6px;
  }
  & div{
    background-color: #000;
    height: 70%;
    width: 3px;
    margin-left: 13px;
  }
  @media only screen and (max-width: 370px) {
    & img,
    & div{
      display: none;
    }
  }
`
export const NavigationContainer = styled.div`
  display: flex;
  height: 100%;
  & ul{
    display: flex;
  }
  & ul *{
    transition: all 0.5s;
  }
  & li{
    position: relative;
    display: flex;
    align-items: center;
  }
  & li button{
    color: #706e6e;
    position: absolute;
    font-size: 1.3rem;
    height: 100%;
    background: #FFF;
    width: 36px;
    left: 0;
  }
  & li button:hover{
    color: #FFF;
    background: #706E6E;
  }
  & li a:hover{
    color: #FFF;
    background: #706E6E;
  }
  & li a{
    display: block;
    background-color: #FFF;
    padding-left: 13px;
    padding-top: 5px;
    padding-right: 13px;
    height: 100%;
    font-size: 27px;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-family: SolanoGothicMVBStd-Bd;
    color: #706e6e;
  }
  & .categories{
    position: absolute;
    top: 60px;
    z-index: -1;
    width: max-content;
    left: 0;
    height: 0px;
    overflow: hidden;
  }
  & .categories-active{
    height: 400px !important;
  }
  & .categories ul{
    background: #fff;
    display: block;
  }
  & .categories ul li{
  }
  & .categories ul a{
    font-size: 1.2rem;
    margin-top: 0px;
    display: flex;
    width: 100%;
    align-items: center;
  }
  @media only screen and (max-width: 1180px) {
    & li a{
      font-size: 24px;
      padding-left: 6px;
      padding-right: 6px;
    }
  }
  @media only screen and (max-width: 991px) {
    & li{
      display: none;
    }
  }
`

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  & input{
    height: 30px;
    border: 2px solid #726e6e;
    border-radius: 0;
    width: 210px;
    padding: 0 6px;
    margin-left: 15px;
  }
  & button{
    width: 19px;
    position: absolute;
    right: 5px;
  }
  & button img{
    width: 100%;
  }
  @media only screen and (max-width: 991px) {
    display: none;
  }
`

export const SearchContainerResponsive = styled.div`
  display: none;
  align-items: center;
  & input{
    height: 30px;
    border: 2px solid #726e6e;
    border-radius: 0;
    width: 210px;
    padding: 0 6px;
    margin-left: 15px;
  }
  & button{
    width: 19px;
    position: absolute;
    right: 5px;
  }
  & button img{
    width: 100%;
  }
  @media only screen and (max-width: 991px) {
    display: flex;
    position: absolute;
    left: 0;
    width: 100%;
    top: 60px;
    pointer-events: none;
    opacity: 0;
    transition: all 0.5s;
    height: 60px;
    background: #FFF;
    & input{
      height: 36px;
      width: 100%;
      margin-right: 15px;
    }
    & button{
      width: 24px;
      position: absolute;
      right: 22px;
    }
  }
`

export const Title = styled.h2`
    font-size: 35px;
    color: #000;
    font-weight: 600;
    margin-top: 17px;
    font-family: SolanoGothicMVBStd-Bd;
`