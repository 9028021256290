import React, { useState } from 'react';
import {
  MenuRightContainer
} from "./upcc-styles";
import Logo from "../../images/logo_upc.png";
import { Link } from 'gatsby';

const MenuRight = () => {
  const [showRightMenu, setShowRightMenu] = useState(false);
  return (
    <>
      <MenuRightContainer className="py-4 px-3" showRightMenu={showRightMenu}>
        <div className="d-flex justify-content-between">
          <img src={Logo} alt="upc cultural" />
          <button className="mt-1" onClick={() => setShowRightMenu(!showRightMenu)} id="menu-right-control"><span>X</span></button>
        </div>
        <ul>
          <li><Link to="/noticias">NOTICIAS</Link></li>
          <li><Link to="/galeria">GALERÍA</Link></li>
          <li><Link to="/programas">PROGRAMAS</Link></li>
          <li><Link to="/podcasts">PODCASTS</Link></li>
          <li><Link to="/agenda">AGENDA</Link></li>
        </ul>
      </MenuRightContainer>
    </>
  )
}

export default MenuRight
